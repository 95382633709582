export default class Utils {
  public static getEntrance(): string {
    /* 判断入口 */
    let typeApp: string = '';
    const browser: any = navigator.userAgent;
    if (
      browser.toLowerCase().match(/Alipay/i) == 'alipay' &&
      !browser.toLowerCase().match(/koubei/i)
    ) {
      typeApp = 'alipay';
    } else if (browser.toLowerCase().match(/eleme/i) == 'eleme') {
      typeApp = 'eleme';
    } else if (browser.toLowerCase().match(/koubei/i) == 'koubei') {
      typeApp = 'koubei';
      //@ts-ignore
    } else if (browser.toLowerCase().match(/DingTalk/i) == 'dingtalk') {
      typeApp = 'dingding';
    } else if (browser.toLowerCase().match(/weibo/i) == 'weibo') {
      typeApp = 'weibo';
    }
    return typeApp;
  }
}
