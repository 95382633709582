






















import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Mixin from "../Mixin/Mixin";

@Component
export default class LoadingPage extends Mixin {
  @Prop()
  public now!: string;
}
