import Store from '../store/index';
let upLoadUrl = process.env.VUE_APP_UPLOAD_URL;
var mainfest = [
  {src: upLoadUrl + 'image/bar.png', id: 'loadingBarBg'},
  {
    src: upLoadUrl + 'image/loading-bg.png',
    id: 'loadingBg'
  },
  {
    src: upLoadUrl + 'image/indexBg1.png',
    id: 'IndexPageBg'
  },
  {src: upLoadUrl + 'image/play.png', id: 'playBtn'},
  {
    src: upLoadUrl + 'image/posterStart.png',
    id: 'postarStart'
  },
  {
    src: upLoadUrl + 'image/endPoster.png',
    id: 'postarStart'
  },
  {
    src: upLoadUrl + 'image/title.png',
    id: 'loadingTitle'
  },
  {src: upLoadUrl + 'video/eleme.mp4', id: 'videoDom'},
  {
    src: upLoadUrl + 'image/box.gif',
    id: 'loadingTitle'
  },
  {
    src: upLoadUrl + 'image/b1.png',
    id: 'loadingTitle'
  },
  {
    src: upLoadUrl + 'image/b2.png',
    id: 'loadingTitle'
  },
  {
    src: upLoadUrl + 'image/b3.png',
    id: 'loadingTitle'
  },
  {
    src: upLoadUrl + 'image/b4.png',
    id: 'loadingTitle'
  }
];

var preload = {
  // 预加载函数
  startPreload: function() {
    //@ts-ignored
    var preload: any = new createjs.LoadQueue(true);
    //为preloaded添加整个队列变化时展示的进度事件
    preload.addEventListener('progress', this.handleFileProgress);
    //注意加载音频文件需要调用如下代码行
    //@ts-ignored
    preload.installPlugin(createjs.SOUND);
    //为preloaded添加当队列完成全部加载后触发事件
    preload.addEventListener('complete', this.loadComplete);
    //设置最大并发连接数  最大值为10
    preload.setMaxConnections(1);
    preload.loadManifest(mainfest);
  },
  // 当整个队列变化时展示的进度事件的处理函数
  handleFileProgress: function(event: any) {
    let progress = Math.ceil(event.loaded * 100) + '%';
    Store.commit('setProgress', progress);
  },
  // 处理preload添加当队列完成全部加载后触发事件
  loadComplete: function() {
    return new Promise((resolve, reject) => {
      resolve();
    });
  }
};
export default preload;
// preload.startPreload();
