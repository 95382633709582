import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    progress: ''
  },
  mutations: {
    setProgress: (state: any, progress: string) => {
      state.progress = progress;
    }
  },
  actions: {},
  modules: {}
});
