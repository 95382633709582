





































































// @ is an alias to /src
import Vue from "vue";
import Mixin from "../Mixin/Mixin";
import { Component, Prop, Watch } from "vue-property-decorator";
import LoadingPage from "../components/LoadingPage.vue";
import preload from "../utils/preloadJs";
import Utils from "../utils/utils";
interface videoObj {
  poster: string;
  rePlayTime: number;
}
interface shenXianObj {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  width: number;
  height: number;
  zIndex: number;
  transform?: string;
  name: string;
  kbUrl: string;
  elemeUrl: string;
  ref?: string;
}
//@ts-ignored
@Component({
  components: {
    LoadingPage,
  },
})
export default class Home extends Mixin {
  private showTip: boolean = false;
  private boxImage: string = "image/b1.png";
  private ifOpen: boolean = false;
  private showPlayBtn: boolean = true;
  private ratio: number = 0;
  private ifShowHotArea: boolean = false;
  private showLoadingPage: boolean = true;
  private showImg: boolean = true;
  private videoHeight: number = 0;
  private pauseLock: boolean = false;
  private ifShowHint: boolean = true;
  private typeApp: string = "";
  private error: string = "";

  private created() {
    this.typeApp = Utils.getEntrance();
    console.log(this.typeApp);
    // if (this.typeApp == "") return;
    preload.startPreload();
    this.getRatio();
  }

  //这里是监听视频加载的百分比
  @Watch("progress")
  public getProgress(newVal: string) {
    // console.log("percent", newVal);
    // return;
    if (newVal == "100%") {
      setTimeout(() => {
        this.showLoadingPage = false;
      }, 2000);
    }
  }
  public openBox() {
    let that = this;
    this.ifOpen = true;
    let n = 1;
    let clear = setInterval(() => {
      if (n == 4) {
        clearInterval(clear);
        return;
      }
      n++;
      that.boxImage = "image/b" + n + ".png";
      console.log("boxImage", that.boxImage);
    }, 200);
    setTimeout(() => {
      that.toShop({
        kbUrl: "https://tb.ele.me/wow/zele/act/dunhuangipmeishi?wh_biz=tm",
        elemeUrl: "https://tb.ele.me/wow/zele/act/dunhuangipmeishi?wh_biz=tm",
      });
    }, 3000);
  }
  public hiddenImgAndPlayVideo() {
    //开始播放视频
    this.videoLoaded();
  }
  private getRatio() {
    /* 屏幕比例计算 */
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;
    this.ratio = screenWidth / 375;
    this.videoHeight = (2000 / 1000) * screenWidth;
  }
  private videoLoaded() {
    let that = this;
    const videoDom: any = document.getElementById("videoEle");
    //开始播放视频

    videoDom.play();
    that.pauseLock = false;
    this.showPlayBtn = false;
    //开始播放监听
    videoDom.onplay = () => {
      setTimeout(() => {
        that.showImg = false;
      }, 400);
    };
    //播放之后监听
    videoDom.onended = function () {
      this.pause();
      // that.showImg = true;
      that.ifShowHotArea = true;
      setTimeout(() => {
        that.ifShowHint = false;
      }, 1500);
    };
  }
  private videoPause() {
    let that = this;
    const videoDom: any = document.getElementById("videoEle");
    //开始播放视频
    that.pauseLock = false;
    videoDom.currentTime = 0;
    videoDom.pause();
  }
  private skipVideo() {
    this.endVideo();
  }
  private endVideo() {
    this.ifShowHotArea = true;
    this.videoPause();
    // this.$nextTick(() => {
    //   (document.getElementById('DOM') as any).scrollIntoView({
    //     behavior: 'auto',
    //     block: 'end'
    //   });
    // });
    setTimeout(() => {
      this.ifShowHint = false;
    }, 1500);
  }
  private toShop(shopItem: any) {
    console.log(shopItem);
    let typeApp = Utils.getEntrance();
    if (typeApp == "eleme" || typeApp == "dingding" || typeApp == "weibo") {
      //饿了么单独处理
      if (shopItem.elemeUrl) {
        window.location.href = shopItem.elemeUrl;
      }
    } else if (typeApp == "alipay" || typeApp == "koubei") {
      //1-支付宝
      //@ts-ignored
      AlipayJSBridge.call("pushWindow", {
        url: shopItem.kbUrl,
        param: {
          readTitle: true,
          showOptionMenu: false,
        },
      });
    } else {
      this.error = typeApp;
      this.showTip = true;
    }
  }
}
