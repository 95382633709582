import Vue from 'vue';
import {State, Mutation} from 'vuex-class';
export default class Mixin extends Vue {
  @State((state) => state.progress)
  public progress!: string;
  public isCompleteUrl(url: string): boolean {
    return (
      url == null ||
      url.startsWith('//') ||
      url.startsWith('http://') ||
      url.startsWith('https://')
    );
  }

  /**
   * 获取上传资源链接
   * @param url url
   * @return 拼接后url
   */
  public getImageUrl(url: string): string {
    if (this.isCompleteUrl(url)) {
      return url;
    }
    return process.env.VUE_APP_UPLOAD_URL + url;
  }
}
